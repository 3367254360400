import React, { useEffect, useState } from "react";
import {
  Button,
  CircularProgress,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControl,
  Grid,
  TextField,
} from "@material-ui/core";
import api from "../../services/api";
import toastError from "../../errors/toastError";
import Dialog from "../Dialog";
import { Field, Form, Formik } from "formik";
import { green } from "@material-ui/core/colors";
import { Autocomplete } from "@material-ui/lab";
import { makeStyles } from "@material-ui/styles";

const seguimientoInitialState = {
  clientId: null,
  seguimientoId: null,
  sendTime: null,
};

const drawerWidth = 320;

const useStyles = makeStyles((theme) => ({
  drawer: {
    width: drawerWidth,
    flexShrink: 0,
  },
  drawerPaper: {
    width: drawerWidth,
    display: "flex",
    borderTop: "1px solid rgba(0, 0, 0, 0.12)",
    borderRight: "1px solid rgba(0, 0, 0, 0.12)",
    borderBottom: "1px solid rgba(0, 0, 0, 0.12)",
    borderTopRightRadius: 4,
    borderBottomRightRadius: 4,
  },
  header: {
    display: "flex",
    borderBottom: "1px solid rgba(0, 0, 0, 0.12)",
    backgroundColor: theme.palette.contactdrawer, //DARK MODE PLW DESIGN//
    alignItems: "center",
    padding: theme.spacing(0, 1),
    minHeight: "73px",
    justifyContent: "flex-start",
  },
  content: {
    display: "flex",
    backgroundColor: theme.palette.contactdrawer, //DARK MODE PLW DESIGN//
    flexDirection: "column",
    padding: "8px 0px 8px 8px",
    height: "100%",
    overflowY: "scroll",
    ...theme.scrollbarStyles,
  },

  contactAvatar: {
    margin: 15,
    width: 100,
    height: 100,
  },

  contactHeader: {
    display: "flex",
    padding: 8,
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center",
    "& > *": {
      margin: 4,
    },
  },

  contactDetails: {
    marginTop: 8,
    padding: 8,
    display: "flex",
    flexDirection: "column",
  },
  contactExtraInfo: {
    marginTop: 4,
    padding: 6,
  },
  tabs: {
    width: 400,
    minHeight: 450,
  },
  multFieldLine: {
    display: "flex",
    "& > *:not(:last-child)": {
      marginRight: theme.spacing(1),
    },
    marginTop: 12,
  },

  btnWrapper: {
    position: "relative",
  },

  buttonProgress: {
    color: green[500],
    position: "absolute",
    top: "50%",
    left: "50%",
    marginTop: -12,
    marginLeft: -12,
  },
  formControl: {
    margin: theme.spacing(1),
    minWidth: 120,
  },
}));

const EventClientModal = ({ contact, open, onClose }) => {
  const classes = useStyles();

  const [seguimiento, setSeguimiento] = useState(seguimientoInitialState);
  const [seguimientos, setSeguimientos] = useState([]);
  const [currentContact, setCurrentContact] = useState(contact);
  const [currentSeguimiento, setCurrentSeguimiento] = useState({
    id: "",
    name: "",
  });

  const fetchCampaigns = async () => {
    try {
      const { data } = await api.get("/eventos/select");
      setSeguimientos(data);
    } catch (err) {
      toastError(err);
    }
  };

  const assignSeguimiento = async (values) => {
    try {
      await api.post("/eventos/clientes", {
        eventoId: currentSeguimiento.id,
        clientId: contact.id,
      });
      onClose();
    } catch (err) {
      toastError(err);
    }
  };

  useEffect(() => {
    fetchCampaigns();
    setCurrentContact(contact);
  }, []);

  return (
    <Dialog
      maxWidth="xs"
      fullWidth
      scroll="paper"
      modalOpen={open}
      onClose={() => onClose(false)}
    >
      <DialogTitle>Recordatorio</DialogTitle>
      <div style={{ width: 400 }}>
        <Formik
          initialValues={seguimientoInitialState}
          enableReinitialize={true}
          onSubmit={(values, actions) => {
            setTimeout(() => {
              assignSeguimiento(values);
              actions.setSubmitting(false);
            }, 400);
          }}
        >
          {({ touched, errors, isSubmitting, values, setFieldValue }) => (
            <Form>
              <DialogContent
                dividers
                style={{ display: "flex", gap: 4, flexDirection: "column" }}
              >
                {/* <div className={classes.multFieldLine}>
									<FormControl
										variant="outlined"
										fullWidth
									>
										<Autocomplete
											fullWidth
											value={currentContact}
											options={[contact]}
											// onChange={(e, contact) => {
											// 	// const contactId = contact ? contact.id : '';
											// 	setSeguimiento({ ...seguimiento, contact });
											// 	setCurrentContact(contact ?? initialContact);
											// }}
											getOptionLabel={(option) => option.name}
											getOptionSelected={(option, value) => {
												return value.id === option.id
											}}
											renderInput={(params) => <TextField {...params} variant="outlined" placeholder="Contato" />}
										/>
									</FormControl>
								</div> */}
                <div className={classes.multFieldLine}>
                  <FormControl variant="outlined" fullWidth>
                    <Autocomplete
                      fullWidth
                      value={currentSeguimiento}
                      options={seguimientos}
                      onChange={(e, campaignId) => {
                        // const contactId = ccontactsontact ? contact.id : '';
                        setSeguimiento({ ...seguimiento, campaignId });
                        setCurrentSeguimiento(campaignId ?? currentSeguimiento);
                      }}
                      getOptionLabel={(option) => option.name}
                      getOptionSelected={(option, value) => {
                        return value.id === option.id;
                      }}
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          variant="outlined"
                          placeholder="Recordatorio"
                        />
                      )}
                    />
                  </FormControl>
                </div>
                <Grid xs={12} md={4} item></Grid>
              </DialogContent>
              <DialogActions>
                <Button
                  type="submit"
                  color="primary"
                  disabled={isSubmitting}
                  variant="contained"
                  className={classes.btnWrapper}
                >
                  Assignar Recordatorio
                  {isSubmitting && (
                    <CircularProgress
                      size={24}
                      className={classes.buttonProgress}
                    />
                  )}
                </Button>
              </DialogActions>
            </Form>
          )}
        </Formik>
      </div>
    </Dialog>
  );
};

export default EventClientModal;
