import React, { useEffect, useMemo, useState, useCallback } from "react";
import { Calendar, momentLocalizer } from "react-big-calendar";
import "react-big-calendar/lib/css/react-big-calendar.css";
import withDragAndDrop from "react-big-calendar/lib/addons/dragAndDrop";
import "react-big-calendar/lib/addons/dragAndDrop/styles.css";
import moment from "moment";
import "moment/locale/es";
import api from "../../services/api";
import toastError from "../../errors/toastError";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";
import "../Calendar/calendar.css";

moment.locale("es");

const CalendarPage = () => {
  const history = useHistory();
  const DnDCalendar = withDragAndDrop(Calendar);
  const localizer = momentLocalizer(moment);

  const { components } = useMemo(
    () => ({
      components: {
        event: EventComponent,
      },
    }),
    []
  );
  const [view, setView] = useState("month");

  const [events, setEvents] = useState([]);
  const [dateStart, setDateStart] = useState(
    moment().startOf("month").format("YYYY-MM-DD")
  );
  const [dateEnd, setDateEnd] = useState(
    moment().endOf("month").format("YYYY-MM-DD")
  );

  const handleNavigate = (date) => {
    setDateEnd(moment(date).endOf("month").format("YYYY-MM-DD"));
    setDateStart(moment(date).startOf("month").format("YYYY-MM-DD"));
  };

  const getEvents = async () => {
    try {
      const { data } = await api.get("/schedules/", {
        params: { dateEnd, dateStart, type: "calendario" },
      });

      if (Array.isArray(data?.schedules)) {
        setEvents(mapEvents(data?.schedules));
      }
    } catch (err) {
      toastError(err);
    }
  };

  const mapEvents = (data) =>
    data.map((item) => ({
      id: item.id,
      uuid: item?.ticket?.uuid,
      title: `\n ${item.contact.name}`,
      resource: `${item.contact.name}`,
      start: new Date(item.sendAt),
      end: moment(new Date(item.sendAt)).add(1, "hours").toDate(),
      color: item.color,
    }));
  const handleViewChange = (view) => {
    setView(view);
  };

  const handleEventDrop = useCallback(
    async ({ event, start, end, isAllDay: droppedOnAllDaySlot }) => {
      const updatedEvent = { ...event, start, end };
      setEvents((prevEvents) =>
        prevEvents.map((evt) => (evt.id === event.id ? updatedEvent : evt))
      );

      try {
        const { data } = await api.get(`/schedules/${event.id}`);
        data.sendAt = start;

        await api.put(`/schedules/${data.id}`, data);
      } catch (err) {
        toastError(err);
      }
    },
    []
  );

  useEffect(() => {
    getEvents();
  }, [dateEnd, dateStart]);

  return (
    <div style={{ padding: 4 }}>
      <DnDCalendar
        showAllEvents={true}
        localizer={localizer}
        components={components}
        onView={handleViewChange}
        events={events}
        cultures={["es"]}
        defaultView={view}
        style={{ height: 600 }}
        onNavigate={handleNavigate}
        onSelectEvent={(e) =>
          history.push(e?.uuid ? "/tickets/" + e.uuid : "/tickets")
        }
        onEventDrop={handleEventDrop}
        messages={{
          week: "Semana",
          work_week: "Semana de trabajo",
          day: "Día",
          month: "Mes",
          previous: "Atrás",
          next: "Adelante",
          today: "Hoy",
          agenda: "Agenda",
          showMore: (total) => `+${total} más`,
        }}
      />
    </div>
  );
};

export default CalendarPage;

const EventComponent = ({ event }) => {
  console.log("event", event);
  return (
    <div
      style={{
        backgroundColor: event.color ?? "#3C10DE",
        maxHeight: 200,
        paddingLeft: "4px",
        paddingRight: "16px",
        borderRadius: "5px",
      }}
    >
      <p style={{ marginBottom: "0px" }}>{event.title}</p>
      <div className="row">
        <div className="col-6">
          <p style={{ marginBottom: "0px", fontSize: "10px" }}>
            Desde: {moment(event?.start).format("HH:mm")}
          </p>
        </div>
        <div className="col-6">
          <p style={{ marginBottom: "0px", fontSize: "10px" }}>
            Hasta: {moment(event?.end).format("HH:mm")}
          </p>
        </div>
      </div>
    </div>
  );
};
