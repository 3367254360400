import React, { useEffect, useState, useContext } from "react";
import {
  Button,
  CircularProgress,
  Checkbox,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControl,
  Grid,
  TextField,
} from "@material-ui/core";
import api from "../../services/api";
import toastError from "../../errors/toastError";
import Dialog from "../Dialog";
import { Field, Form, Formik } from "formik";
import { green } from "@material-ui/core/colors";
import { Autocomplete } from "@material-ui/lab";
import { makeStyles } from "@material-ui/styles";
import { i18n } from "../../translate/i18n";
import moment from "moment";
import { Label } from "recharts";
import { AuthContext } from "../../context/Auth/AuthContext";

const seguimientoInitialState = {
  clientId: null,
  seguimientoId: null,
  sendTime: null,
  scheduledAt: "",
  scheduledAt: new Date().toISOString().slice(0, 16),
};

const drawerWidth = 320;

const useStyles = makeStyles((theme) => ({
  drawer: {
    width: drawerWidth,
    flexShrink: 0,
  },
  drawerPaper: {
    width: drawerWidth,
    display: "flex",
    borderTop: "1px solid rgba(0, 0, 0, 0.12)",
    borderRight: "1px solid rgba(0, 0, 0, 0.12)",
    borderBottom: "1px solid rgba(0, 0, 0, 0.12)",
    borderTopRightRadius: 4,
    borderBottomRightRadius: 4,
  },
  header: {
    display: "flex",
    borderBottom: "1px solid rgba(0, 0, 0, 0.12)",
    backgroundColor: theme.palette.contactdrawer, //DARK MODE PLW DESIGN//
    alignItems: "center",
    padding: theme.spacing(0, 1),
    minHeight: "73px",
    justifyContent: "flex-start",
  },
  content: {
    display: "flex",
    backgroundColor: theme.palette.contactdrawer, //DARK MODE PLW DESIGN//
    flexDirection: "column",
    padding: "8px 0px 8px 8px",
    height: "100%",
    overflowY: "scroll",
    ...theme.scrollbarStyles,
  },

  contactAvatar: {
    margin: 15,
    width: 100,
    height: 100,
  },

  contactHeader: {
    display: "flex",
    padding: 8,
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center",
    "& > *": {
      margin: 4,
    },
  },

  contactDetails: {
    marginTop: 8,
    padding: 8,
    display: "flex",
    flexDirection: "column",
  },
  contactExtraInfo: {
    marginTop: 4,
    padding: 6,
  },
  tabs: {
    width: 400,
    minHeight: 450,
  },
  multFieldLine: {
    display: "flex",
    "& > *:not(:last-child)": {
      marginRight: theme.spacing(1),
    },
    marginTop: 12,
  },

  btnWrapper: {
    position: "relative",
  },

  buttonProgress: {
    color: green[500],
    position: "absolute",
    top: "50%",
    left: "50%",
    marginTop: -12,
    marginLeft: -12,
  },
  formControl: {
    margin: theme.spacing(1),
    minWidth: 120,
  },
}));

const SeguimientoModal = ({ contact, open, onClose, ticketId }) => {
  const classes = useStyles();
  const { user } = useContext(AuthContext);
  const [seguimiento, setSeguimiento] = useState(seguimientoInitialState);
  const [seguimientos, setSeguimientos] = useState([]);
  const [currentContact, setCurrentContact] = useState(contact);
  const [schedule, serSchedule] = useState(false);
  // const [contacts, setContacts] = useState([initialContact]);
  const [currentSeguimiento, setCurrentSeguimiento] = useState({
    id: "",
    name: "",
    color: "",
  });

  const [desableBtn, setDesableBtn] = useState(false);

  const fetchCampaigns = async () => {
    try {
      const { data } = await api.get("/seguimientos/select");
      setSeguimientos(data);
    } catch (err) {
      toastError(err);
    }
  };

  const assignSeguimiento = async (values) => {
    if (!desableBtn) {
      setDesableBtn(true);
      try {
        const valuesSchedule = {
          body: "Agendamiento Manual",
          contactId: contact.id,
          sendAt:
            values.scheduledAt !== ""
              ? values.scheduledAt
              : currentSeguimiento.fromDate,
          userId: user.id,
          ticketId: ticketId,
          color: currentSeguimiento.color ?? "#3C10DE",
        };
        if (currentSeguimiento.name !== "") {
          await api.post("/seguimientos/clientes", {
            seguimientoId: currentSeguimiento.id,
            clientId: contact.id,
            sendTime: values.sendTime,
            scheduledAt:
              values.scheduledAt !== ""
                ? values.scheduledAt
                : currentSeguimiento.fromDate,
          });
        }
        if (schedule) {
          const { data } = await api.get(`/schedulesc/${contact.id}`);
          if (!data) {
            await api.post("/schedules", valuesSchedule);
          } else {
            data.sendAt =
              values.scheduledAt !== ""
                ? values.scheduledAt
                : currentSeguimiento.fromDate;
            data.color = currentSeguimiento.color ?? "#3C10DE";
            await api.put(`/schedules/${data.id}`, data);
          }
        }
        setDesableBtn(false);
        onClose();
      } catch (err) {
        setDesableBtn(false);
        toastError(err);
      }
    }
  };

  const setScheduling = (event) => {
    serSchedule(event.target.checked);
  };

  useEffect(() => {
    fetchCampaigns();
    setCurrentContact(contact);
  }, []);

  return (
    <Dialog
      maxWidth="xs"
      fullWidth
      scroll="paper"
      modalOpen={open}
      onClose={() => onClose(false)}
    >
      <DialogTitle>Seguimiento</DialogTitle>
      <div style={{ width: 400 }}>
        <Formik
          initialValues={seguimientoInitialState}
          enableReinitialize={true}
          onSubmit={(values, actions) => {
            setTimeout(() => {
              assignSeguimiento(values);
              actions.setSubmitting(false);
            }, 400);
          }}
        >
          {({ touched, errors, isSubmitting, values, setFieldValue }) => (
            <Form>
              <DialogContent
                dividers
                style={{ display: "flex", gap: 4, flexDirection: "column" }}
              >
                {/* <div className={classes.multFieldLine}>
									<FormControl
										variant="outlined"
										fullWidth
									>
										<Autocomplete
											fullWidth
											value={currentContact}
											options={[contact]}
											// onChange={(e, contact) => {
											// 	// const contactId = contact ? contact.id : '';
											// 	setSeguimiento({ ...seguimiento, contact });
											// 	setCurrentContact(contact ?? initialContact);
											// }}
											getOptionLabel={(option) => option.name}
											getOptionSelected={(option, value) => {
												return value.id === option.id
											}}
											renderInput={(params) => <TextField {...params} variant="outlined" placeholder="Contato" />}
										/>
									</FormControl>
								</div> */}
                <div className={classes.multFieldLine}>
                  <FormControl variant="outlined" fullWidth>
                    <Autocomplete
                      fullWidth
                      value={currentSeguimiento}
                      options={seguimientos}
                      onChange={(e, campaignId) => {
                        // const contactId = ccontactsontact ? contact.id : '';
                        setSeguimiento({
                          ...seguimiento,
                          campaignId,
                        });

                        setCurrentSeguimiento(campaignId ?? currentSeguimiento);

                        setFieldValue("seguimiento", campaignId);

                        if (campaignId) {
                          setFieldValue(
                            "scheduledAt",
                            moment(campaignId.fromDate).format(
                              "YYYY-MM-DDTHH:mm"
                            )
                          );
                        }
                        serSchedule(false);
                      }}
                      getOptionLabel={(option) => option.name}
                      getOptionSelected={(option, value) => {
                        return value.id === option.id;
                      }}
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          variant="outlined"
                          placeholder="Seguimiento"
                        />
                      )}
                    />
                  </FormControl>
                </div>

                <Grid xs={12} item>
                  <div>
                    <Field
                      as={TextField}
                      label={i18n.t("Fecha de agendamiento")}
                      name="scheduledAt"
                      error={touched.scheduledAt && Boolean(errors.scheduledAt)}
                      helperText={touched.scheduledAt && errors.scheduledAt}
                      variant="outlined"
                      margin="dense"
                      type="datetime-local"
                      lang="es-MX"
                      InputLabelProps={{
                        shrink: true,
                      }}
                      fullWidth
                      className={classes.textField}
                      //   disabled={!campaignEditable}
                    />
                  </div>
                  {/* <Field
                      as={TextField}
                      label={"Hora"}
                      name="sendTime"
                      error={touched.sendTime && Boolean(errors.sendTime)}
                      helperText={touched.sendTime && errors.sendTime}
                      variant="outlined"
                      margin="dense"
                      type="time"
                      InputLabelProps={{
                        shrink: true,
                      }}
                      fullWidth
                      style={{width: 140}}
                    //   disabled={!campaignEditable}
                    /> */}
                </Grid>
              </DialogContent>
              <DialogActions>
                <div className="h6 my-auto">Agendar</div>
                <Checkbox
                  style={{
                    width: "30px",
                    height: "30px",
                    marginRight: "50px",
                  }}
                  checked={schedule}
                  onChange={setScheduling}
                  variant="outlined"
                />
                <Button
                  type="submit"
                  color="primary"
                  disabled={isSubmitting || desableBtn}
                  variant="contained"
                  className={classes.btnWrapper}
                >
                  Assignar Seguimiento
                  {isSubmitting && (
                    <CircularProgress
                      size={24}
                      className={classes.buttonProgress}
                    />
                  )}
                </Button>
              </DialogActions>
            </Form>
          )}
        </Formik>
      </div>
    </Dialog>
  );
};

export default SeguimientoModal;
